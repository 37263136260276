import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";
// import { FiArrowRightCircle } from "react-icons/fi";
// import { Link } from "gatsby";
// import Image from "@components/common/CloudinaryImage";
// import Title from "@components/common/Title";
// import Text from "@components/common/Text";
// import { InlineWidget } from "react-calendly";
// import { FaParking } from "react-icons/fa";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <div className="space-y-5">
                <p class="highlight">
                    <span role="img" aria-label="Exclamacions" class="emoji">
                        ‼️
                    </span>
                    <strong>Arriba la vuitena edició!</strong>
                    <span role="img" aria-label="Exclamacions" class="emoji">
                        ‼️
                    </span>
                </p>

                <p>
                    <strong>
                        Un curs dissenyat especialment per a opositors, aspirants ISPC i policies en
                        actiu.
                    </strong>
                </p>

                <p class="date">
                    <span aria-label="fecha" role="img">
                        📅
                    </span>{" "}
                    <strong>Data:</strong> Dissabte 28 de setembre
                </p>
                <p class="date">
                    <span aria-label="time" role="img">
                        🕓
                    </span>{" "}
                    <strong>Horari:</strong> De 08:00 a 15:00 h
                </p>

                <p>
                    <strong>
                        <span aria-label="comment" role="img">
                            💬
                        </span>{" "}
                        Curs intensiu de 7 hores
                    </strong>
                </p>

                <p>Ens centrarem en els conceptes més importants de la 4/2015 i del codi penal.</p>

                <ul className="">
                    <li>- Infraccions</li>
                    <li>- Diferència entre delictes lleus, menys greus i greus.</li>
                    <li>- Identificacions</li>
                    <li>- Detencions</li>
                    <li>- Judicis ràpids</li>
                    <li>- El domicili</li>
                    <li>- L'ocupació</li>
                    <li>- Armes i drogues</li>
                    <li>- Delictes sexuals</li>
                </ul>

                <p>
                    <strong>
                        <span aria-label="dossier" role="img">
                            📒
                        </span>{" "}
                        Dossier de regal per a tots els assistents
                    </strong>
                    , amb resums del temari i diversos esquemes operatius que faran més comprensible
                    les parts més complexes del temari.
                </p>

                <p>
                    <span aria-label="ubicació" role="img">
                        🏬
                    </span>{" "}
                    <strong>Ubicació:</strong> Hospitalet de Llobregat
                </p>

                <p class="highlight">
                    <strong>
                        <span aria-label="precio" role="img">
                            💸
                        </span>{" "}
                        Preu:
                    </strong>{" "}
                    59,99€
                </p>

                <p>
                    <strong class="important">
                        No deixis passar aquesta oportunitat única de preparar-te a fons per a la
                        teva oposició!
                    </strong>
                </p>
                <p>
                    <strong class="important">¡Assegura't el teu lloc i no et quedis fora!</strong>
                </p>

                {/*<div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                    <iframe
                        title={item.title}
                        width="100%"
                        height="600px"
                        src="https://www.youtube.com/embed/DhSN3TTtzC4"
                        frameBorder={"0"}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen={true}
                    />
                </div>*/}
            </div>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="Un curs per opositors, aspirants a ISPC i policies en actiu!"
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};
export default Product;
